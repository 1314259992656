import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import NavButtons from 'components/Outdoor_Cameras/IN-9420_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9420 2k+ WQHD Usermanual",
  "path": "/Outdoor_Cameras/IN-9420_WQHD/Usermanual/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-9420 2k+ WQHD Outdoor IP Camera Usermanual",
  "image": "../P_SearchThumb_IN-9020HD.png",
  "social": "/images/Search/P_SearchThumb_IN-9020HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9020HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-9420 2k+ WQHD Manual Video Streaming' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-9420 2k+ WQHD is the first INSTAR camera with the new 1440p chipset.' image='/images/Search/P_SearchThumb_IN-9020HD.png' twitter='/images/Search/P_SearchThumb_IN-9020HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9420_WQHD/Handbuch/' locationFR='/fr/Outdoor_Cameras/IN-9420_WQHD/Usermanual/' crumbLabel="Usermanual" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-9420-2k-wqhd-usermanual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-9420-2k-wqhd-usermanual",
        "aria-label": "in 9420 2k wqhd usermanual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9420 2k+ WQHD Usermanual`}</h1>
    <h2 {...{
      "id": "online-product-description",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#online-product-description",
        "aria-label": "online product description permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Online Product Description`}</h2>
    <h3 {...{
      "id": "table-of-content",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#table-of-content",
        "aria-label": "table of content permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Table of Content`}</h3>
    <ul>
      <li parentName="ul">
        <Link to="/Outdoor_Cameras/IN-9420_WQHD/" mdxType="Link">Overview</Link>
      </li>
      <li parentName="ul">
        <Link to="/Outdoor_Cameras/IN-9420_WQHD/Usermanual" mdxType="Link">Online Usermanual</Link>
      </li>
      <li parentName="ul">
        <Link to="/Outdoor_Cameras/IN-9420_WQHD/Quick_Installation" mdxType="Link">Camera Installation</Link>
      </li>
      <li parentName="ul">
        <Link to="/Outdoor_Cameras/IN-9420_WQHD/Safety_Warnings/" mdxType="Link">Safety Warnings</Link>
      </li>
      <li parentName="ul">
        <Link to="/Outdoor_Cameras/IN-9420_WQHD/Product_Features/" mdxType="Link">Product Features</Link>
      </li>
      <li parentName="ul">
        <Link to="/Outdoor_Cameras/IN-9420_WQHD/Technical_Specifications/" mdxType="Link">Technical Specifications</Link>
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1440p_Series/" mdxType="Link">Web User Interface</Link>
      </li>
      <li parentName="ul">
        <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/" mdxType="Link">Software Update</Link>
      </li>
      <li parentName="ul">
        <Link to="/Downloads/Outdoor_Cameras/IN-9420_WQHD/" mdxType="Link">Downloads</Link>
      </li>
      <li parentName="ul">
        <Link to="/Outdoor_Cameras/IN-9420_WQHD/Video_Streaming/" mdxType="Link">Video Streaming</Link>
      </li>
      <li parentName="ul">
        <Link to="/Outdoor_Cameras/IN-9420_WQHD/Camera_Reset/" mdxType="Link">Camera Reset</Link>
      </li>
      <li parentName="ul">
        <Link to="/Outdoor_Cameras/IN-9420_WQHD/Lense_Adjustment/" mdxType="Link">Lense Adjustment</Link>
      </li>
      <li parentName="ul">
        <Link to="/Outdoor_Cameras/IN-9420_WQHD/Warranty/" mdxType="Link">Warranty & Disposal</Link>
      </li>
    </ul>
    <p>{`The IN-9420 2k+ WQHD is weather proof and wireless outdoor camera. Built-in, high-powered infrared diodes allow you to see deep into the dark. The integrated IR cut filter on the other hand allows for the capturing of native colours, as seen by the human eye.`}</p>
    <h2 {...{
      "id": "download-our-print-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#download-our-print-manual",
        "aria-label": "download our print manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download our Print Manual`}</h2>
    <OutboundLink href="https://wiki.instar.com/dl/IN-9420WQHD/QuickInstallation-1440p-IN-9420WQHD.pdf" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <EuiButton fill mdxType="EuiButton">PDF User Manual</EuiButton>
    </OutboundLink>
    <h2 {...{
      "id": "frequently-asked-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#frequently-asked-questions",
        "aria-label": "frequently asked questions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Frequently Asked Questions`}</h2>
    <ul>
      <li parentName="ul">
        <Link to="#how-to-save-camera-recordings" mdxType="Link">How to save Camera Recordings</Link>
      </li>
      <li parentName="ul">
        <Link to="#camera-access-via-smartphone-app" mdxType="Link">
  Camera-Access via Smartphone-App
        </Link>
      </li>
      <li parentName="ul">
        <Link to="#external-cables-of-the-outdoor-cameras" mdxType="Link">
  External Cables of the Outdoor-Cameras
        </Link>
      </li>
      <li parentName="ul">
        <Link to="#heater-for-the-outdoor-cameras" mdxType="Link">
  Heater for the Outdoor-Cameras
        </Link>
      </li>
      <li parentName="ul">
        <Link to="#zoom-lense" mdxType="Link">Zoom-Lense</Link>
      </li>
      <li parentName="ul">
        <Link to="#battery-andor-sim-card" mdxType="Link">Battery and/or SIM-Card</Link>
      </li>
      <li parentName="ul">
        <Link to="#length-of-the-alarm-videos" mdxType="Link">Length of the Alarm-Videos</Link>
      </li>
      <li parentName="ul">
        <Link to="#synology-surveillance-station-and-qnap-nas" mdxType="Link">
  Synology Surveillance Station and QNAP NAS
        </Link>
      </li>
    </ul>
    <h3 {...{
      "id": "how-to-save-camera-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-save-camera-recordings",
        "aria-label": "how to save camera recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to save Camera Recordings`}</h3>
    <p>{`In total there are five different ways to save the `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Video Recordings`}</Link>{` of your camera:`}</p>
    <ol>
      <li parentName="ol">{`All of our WQHD IP Cameras come with an internal SD-Card with a capacity of 32GB (interchangeable up to 128GB). `}<OutboundLink href="https://www.instar.com/support/help-tools" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`here`}</OutboundLink>{` you can calculate how long it takes until the storage of the SD-Card is full. The oldest recordings on the card will be overwritten by default (Ring Storage). `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Access your SD Card`}</Link>{` with our smart phone app InstarVision for `}<Link to="/Software/Android/InstarVision/Recording/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/InstarVision/iPhone/Recording/" mdxType="Link">{`iPhone`}</Link>{`, `}<Link to="/Software/iOS/InstarVision/iPad/Recording/" mdxType="Link">{`iPad`}</Link>{`, `}<Link to="/Software/Other_Platforms/InstarVision_Blackberry/" mdxType="Link">{`Blackberry`}</Link>{`, `}<Link to="/Software/Windows/InstarVision/Windows_Phone/Recording/" mdxType="Link">{`Windows Phone`}</Link>{` and `}<Link to="/Software/Windows/InstarVision/Metro/Recording/" mdxType="Link">{`Windows 8/10`}</Link>{`.`}</li>
      <li parentName="ol">{`We offer a `}<strong parentName="li">{`Cloud`}</strong>{`-solution to store alarm recordings of your INSTAR Cameras. The `}<Link to="/Motion_Detection/INSTAR_Cloud/" mdxType="Link">{`INSTAR Cloud`}</Link>{` offers an easy-to-access interface and is the perfect tool for managing your recordings. If you are afraid that your camera and SD-Card could be compromised, the INSTAR Cloud will give you the means to secure your recordings.`}</li>
      <li parentName="ol">{`You can use the `}<Link to="/Software/Windows/InstarVision/" mdxType="Link">{`InstarVision 2.0 Surveillance Center`}</Link>{`, a `}<strong parentName="li">{`Windows PC and macOS Software`}</strong>{`. With this Software it is not only possible to keep an eye on the live video of all your cameras at the same time, you can also start recordings and save them on your computer.`}</li>
      <li parentName="ol">{`It is also possible to use the `}<strong parentName="li">{`FTP`}</strong>{` (File Transfer Protocol) service to send your recordings to other devices, for example to an `}<Link to="/Motion_Detection/Router_as_a_FTP_Server/" mdxType="Link">{`USB-Stick Connected To Your Router`}</Link>{`.`}</li>
      <li parentName="ol">{`If you want to keep continuous recordings you can also use `}<strong parentName="li">{`NAS Systems`}</strong>{`, e.g. `}<Link to="/Software/Other_Platforms/Synology/" mdxType="Link">{`Synology`}</Link>{` or `}<Link to="/Software/Other_Platforms/QNAP/" mdxType="Link">{`QNAP`}</Link>{`. Both offer their own surveillance software and support all our camera models.`}</li>
      <li parentName="ol">{`You can also activate the `}<Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link">{`Email-Service`}</Link>{`, to receive snapshots by email after an alarm was triggered.`}</li>
    </ol>
    <h3 {...{
      "id": "camera-access-via-smartphone-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#camera-access-via-smartphone-app",
        "aria-label": "camera access via smartphone app permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera-Access via Smartphone-App`}</h3>
    <p>{`Use our free App InstarVision for `}<Link to="/Software/Android/InstarVision/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/InstarVision/iPhone/" mdxType="Link">{`iPhone`}</Link>{`, `}<Link to="/Software/iOS/InstarVision/iPad/" mdxType="Link">{`iPad`}</Link>{`, `}<Link to="/Software/Other_Platforms/InstarVision_Blackberry/" mdxType="Link">{`Blackberry`}</Link>{`, `}<Link to="/Software/Windows/InstarVision/Windows_Phone/" mdxType="Link">{`Windows Phone`}</Link>{` and `}<Link to="/Software/Windows/InstarVision/Metro/" mdxType="Link">{`Windows 8/10`}</Link>{` to access the live video of your IP Camera. Moreover, you can use those apps to access your SD Card, stream alarm recordings and control camera functions such as activating-/ deactivating the motion detection or use the camera and your smart phone as an intercom system.`}</p>
    <h3 {...{
      "id": "zoom-lense",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#zoom-lense",
        "aria-label": "zoom lense permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Zoom-Lense`}</h3>
    <p>{`All of our WQHD- and FullHD-Cameras (except the IN-9020 FHD) `}<strong parentName="p">{`do not`}</strong>{` have optical zoom lenses, but do offer a digital zoom.`}</p>
    <h3 {...{
      "id": "battery-andor-sim-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#battery-andor-sim-card",
        "aria-label": "battery andor sim card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Battery and/or SIM-Card`}</h3>
    <p>{`Our cameras do not have an internal `}<strong parentName="p">{`Battery`}</strong>{`. They need to be connected to their power adapter or PoE power source.
Our cameras do not have a `}<strong parentName="p">{`SIM-Card-Slot`}</strong>{`. If you want to use the camera at a location without internet access, you can use a mobile LTE-Router. The camera can then be connected to the router and will have access to the internet.`}</p>
    <h3 {...{
      "id": "length-of-the-alarm-videos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#length-of-the-alarm-videos",
        "aria-label": "length of the alarm videos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Length of the Alarm-Videos`}</h3>
    <p>{`The alarm video starts 3 seconds before the alarm event and ends 15 seconds after - in total the alarm video is 17-18s. If the movement continues after the recording, a new alarm event will be triggered and a new 15s video will be recorded.`}</p>
    <h3 {...{
      "id": "time-lapse-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#time-lapse-recordings",
        "aria-label": "time lapse recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Time Lapse Recordings`}</h3>
    <p>{`With all of our cameras you can create timelapse videos, for example if you want to document the progress for a construction site. Activate the photo series inside the WebUI to take snapshots in an scheduled interval. They can afterwards be combined to a timelapse video. Check our `}<Link to="/Software/Windows/InstarVision/Record/Photoseries/" mdxType="Link">{`InstarVision Time Lapse Guide`}</Link>{` for detailed information on how to create a time lapse video. Alternatively, you can also use free software like `}<Link to="/Frequently_Asked_Question/Video_Editing_with_Davinci_Resolve/" mdxType="Link">{`Davinci Resolve`}</Link>{` or free tools that are a part of the `}<Link to="/Frequently_Asked_Question/Time_lapse/" mdxType="Link">{` macOS and Windows 10`}</Link>{` operating systems.`}</p>
    <h3 {...{
      "id": "synology-surveillance-station-and-qnap-nas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#synology-surveillance-station-and-qnap-nas",
        "aria-label": "synology surveillance station and qnap nas permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Synology Surveillance Station and QNAP NAS`}</h3>
    <p>{`Synology and QNAP have their own surveillance software and can use the live video from our cameras. All of our HD-, FullHD- and WQHD Cameras are compatible. For the implementation of our cameras into their surveillance station you can follow our `}<Link to="/Software/Other_Platforms/Synology/" mdxType="Link">{`Manual`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      